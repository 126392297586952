import React from "react"
import { graphql } from "gatsby"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { Helmet } from "react-helmet"
import LayoutNoStripe from "../../components/layoutNoStripe"
import {
  Section,
} from "../../styledComponents/section"
import HeroBanner from "../../components/HeroBanner"
import { HeroText, HeroHeader } from "../../styledComponents/heroBanner"  
import theEssentialMindfulnessCollection from "../../fixtures/mindfulness-collection"
import AudioCard from "../../components/App/audioCard"

import {
  StyledAudio,
} from "../../styledComponents/app/audioCard"

const TheEssentialMindfulnessCollection = ({ data, location }) => {
  const identity = useIdentityContext()
  const collection = theEssentialMindfulnessCollection
  
  return (
    <>
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />x
    </Helmet>
    <LayoutNoStripe location={location} isHomePage>
      {identity.user ?
        <HeroBanner 
          image="https://www.karinagrant.co.uk/static/1f035ec462f9b0ede0d98e9197063155/d81e8/the-essential-mindfulness-collection.webp" 
          backgroundPosition="0 0" 
          largeBackgroundPosition="0 -260px" 
          largeBackgroundSize="100%"
        >
          <HeroText>
            <HeroHeader>
              {collection[0].programName}
            </HeroHeader>
          </HeroText>
        </HeroBanner>
      :
        <Section>
          <StyledAudio>
          {
            collection[0].audioFiles.map(audio => {
              return (
                <AudioCard 
                  key={audio.title + `-1`}
                  title={audio.title}
                  image={audio.image}
                  audioMp3={audio.audioMp3}
                  duration={audio.duration}
                />     
              )
            })
          }
          </StyledAudio>
        </Section>
      }
    </LayoutNoStripe>
    </>
  )
}

export default TheEssentialMindfulnessCollection

export const pageQuery = graphql`
  query EssentialCollection {
    #ID FOR Essential Mindfulness Collection
    product: wpProduct(id: { eq: "cG9zdDo2OTE0" }) {
      id
      title
      content
      uri
      excerpt
      productData {
        pricefull
        pricesale
        hidesale
      }
      featuredImage {
        node {
            altText
            localFile {
                childImageSharp {
                    fixed {
                        src
                    }
                    fluid {
                        src
                    }
                }
                }
            }
        }
    }

    fullPrice: allStripePrice(filter: {active: {eq: true}, id: {eq: "price_1JUqt1IfnzsCIRSOCSfye650"}}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }

    salePrice: allStripePrice(filter: {active: {eq: true}, id: {eq: "price_1JZhLNIfnzsCIRSOARxr9vxX"}}) {
      edges {
        node {
          id
          active
          currency
          unit_amount
          product {
            id
            name
            active
            images
          }
        }
      }
    }
  }
`